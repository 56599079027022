import React, { useEffect, useState } from 'react';
import { OpenAPIV3 } from 'openapi-types';
import { SchemaObjectTable } from './SchemaObjectTable';
import { DownloadCSV } from './DownloadCSV';
import { Row, transformer } from '../transformer/transformer';
import { stringify } from '../transformer/csv';

type SchemaObjectComponentProps = {
  title: string;
  schema?: OpenAPIV3.SchemaObject;
  type: string;
};

export const SchemaObjectComponent = ({
  schema,
  title,
  type,
}: SchemaObjectComponentProps) => {
  const [data, setData] = useState<Row[]>([]);

  useEffect(
    () => setData(transformer(title, `${type}Body`, schema) ?? []),
    [schema, title, type],
  );

  if (!data.length) {
    return <></>;
  }

  return (
    <>
      <DownloadCSV csv={stringify(data)} title={`${title}.${type}`} />
      <SchemaObjectTable data={data} />
    </>
  );
};
