import React, { useState } from 'react';
import clsx from 'clsx';
import { CodeSnippet } from '@backstage/core-components';
import {
  CardActions,
  Collapse,
  IconButton,
  Theme,
  Tooltip,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

export const MermaidCodeTabCard = ({ code }: { code: string }) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(true);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  return (
    <>
      <CardActions disableSpacing>
        <Tooltip title={expanded ? 'Collapse Diagram' : 'Expand Diagram'}>
          <IconButton
            onClick={handleExpandClick}
            aria-expanded={expanded}
            className={clsx(classes.expand, {
              [classes.expandOpen]: expanded,
            })}
          >
            <ExpandMoreIcon />
          </IconButton>
        </Tooltip>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CodeSnippet
          text={code}
          language="markdown"
          showCopyCodeButton
          showLineNumbers
        />
      </Collapse>
    </>
  );
};
