/* eslint-disable guard-for-in */
import { OpenAPIV3 } from 'openapi-types';

export type Row = {
  Field: string;
  Type:
    | OpenAPIV3.NonArraySchemaObjectType
    | OpenAPIV3.ArraySchemaObjectType
    | 'unknown';
  Required: '+' | '';
  Nullable: '+' | '';
  Description: string;
  Path: string;
};

type MetaInfo = {
  field: string;
  parents: string[];
  isRequired: boolean;
};

function createRow(obj: OpenAPIV3.SchemaObject, meta: MetaInfo): Row {
  return {
    Field: meta.field,
    Type: obj.type ?? 'unknown',
    Required: meta.isRequired ? '+' : '',
    Nullable: obj.nullable ? '+' : '',
    Path: `${meta.parents.join('.')}.${meta.field}`,
    Description: obj.description?.replaceAll('\n', ' ') ?? '',
  };
}

function transformObj(obj: OpenAPIV3.NonArraySchemaObject, meta: MetaInfo) {
  const rows = [createRow(obj, meta)];
  meta.parents = [...meta.parents, meta.field];
  for (const field in obj.properties) {
    meta.field = field;
    meta.isRequired = obj.required?.includes(field) ?? false;
    rows.push(
      ...transform(obj.properties[field] as OpenAPIV3.SchemaObject, meta),
    );
  }

  return rows;
}

function transformArray(obj: OpenAPIV3.ArraySchemaObject, meta: MetaInfo) {
  const rows = [createRow(obj, meta)];
  meta.parents = [...meta.parents, meta.field];

  return rows.concat(
    transform(obj.items as OpenAPIV3.SchemaObject, { ...meta }),
  );
}

function transform(obj: OpenAPIV3.SchemaObject, meta: MetaInfo): Array<Row> {
  if (!obj) return [];

  switch (obj.type) {
    case 'object':
      return transformObj(obj, { ...meta });
    case 'array':
      return transformArray(obj as OpenAPIV3.ArraySchemaObject, { ...meta });
    default:
      return [createRow(obj, meta)];
  }
}

export const transformer = (
  service: string,
  name: string,
  obj?: OpenAPIV3.SchemaObject,
): Array<Row> => {
  const meta: MetaInfo = {
    parents: [service],
    isRequired: true,
    field: name,
  };
  if (obj) {
    return transform(obj, meta);
  }

  return [];
};
