import { CSSProperties } from '@material-ui/core/styles/withStyles';
import React, { useCallback } from 'react';
import svgPanZoom from 'svg-pan-zoom';

const addPanZoom = (svg?: SVGElement | null) => {
  if (svg) {
    try {
      svgPanZoom(svg, {
        maxZoom: 1000,
        minZoom: 0.5,
        zoomEnabled: true,
        controlIconsEnabled: true,
        fit: true,
        center: true,
      });
      // reposition the controls
      const controls = svg.querySelector('g[id="svg-pan-zoom-controls"]');
      // get height and width of svg
      const svgHeight = svg.getBoundingClientRect().height;
      const svgWidth = svg.getBoundingClientRect().width;
      // set the controls to be 15% from the top of the svg and 100px from the right
      controls?.setAttribute(
        'transform',
        `translate(${svgWidth - 100}, ${svgHeight * 0.1})`,
      );
    } catch (err) {
      // eslint-disable-next-line no-console
      console.error(err);
    }
  }
};

export const MermaidChart = ({
  code,
  style,
}: {
  code: string;
  style?: CSSProperties;
}) => {
  const linkSVG = useCallback(
    (ref: HTMLDivElement) => {
      if (ref) {
        ref.innerHTML = code;
        const svg = ref.querySelector('svg');
        if (svg) {
          svg.style.height = 'inherit';
          svg.style.maxWidth = '100%';
          addPanZoom(svg);
        }
      }
    },
    [code],
  );

  return <div ref={linkSVG} style={style} />;
};
