import React from 'react';
import { TabbedCard, CardTab } from '@backstage/core-components';
import { MermaidChartTabCard } from './MermaidChartTabCard';
import { MermaidCodeTabCard } from './MermaidCodeTabCard';

export const MermaidTabbedCardComponent = ({
  code,
  id,
}: {
  code: string;
  id: string;
}) => {
  return (
    <TabbedCard>
      <CardTab label="Chart">
        <MermaidChartTabCard code={code} id={id} />
      </CardTab>

      <CardTab label="Raw Mermaid Code">
        <MermaidCodeTabCard code={code} />
      </CardTab>
    </TabbedCard>
  );
};
