/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import { useShadowRoot } from '@backstage/plugin-techdocs-react';
import React, { useLayoutEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { OpenAPIToCSVComponent } from './OpenApiToCSVComponent';

export type OpenAPIToCSVProps = {};

export const OpenAPIToCSVAddon = () => {
  const parent =
    (useShadowRoot()?.getElementById(
      'openapi-schema-json',
    ) as HTMLDivElement) ?? null;

  const [text, setText] = useState('');

  useLayoutEffect(() => {
    const element = parent?.querySelector('p.json') as HTMLParagraphElement;
    if (parent && element && !text) {
      setText(element.innerText);
    }

    if (parent && element && text) {
      parent.removeChild(element);
    }
  }, [parent, text]);

  return parent && text ? (
    <>{createPortal(<OpenAPIToCSVComponent openApi={text} />, parent)}</>
  ) : null;
};
