import {
  Card,
  CardActions,
  CardContent,
  Chip,
  Collapse,
  IconButton,
  Theme,
  createStyles,
  makeStyles,
} from '@material-ui/core';
import React, { useState } from 'react';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    expand: {
      transform: 'rotate(0deg)',
      marginLeft: 'auto',
      transition: theme.transitions.create('transform', {
        duration: theme.transitions.duration.shortest,
      }),
    },
    expandOpen: {
      transform: 'rotate(180deg)',
    },
  }),
);

type PathExpandProps = {
  name: string;
  method: string;
  children?: JSX.Element | any;
};

export const PathExpand = ({ name, method, children }: PathExpandProps) => {
  const classes = useStyles();
  const [expanded, setExpanded] = useState(false);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };
  if (!name || !method) return null;

  return (
    <Card>
      <CardActions disableSpacing>
        <Chip label={name} color="primary" />
        <Chip label={method} size="small" />
        <IconButton
          onClick={handleExpandClick}
          aria-expanded={expanded}
          className={clsx(classes.expand, {
            [classes.expandOpen]: expanded,
          })}
        >
          <ExpandMoreIcon />
        </IconButton>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <CardContent>{children}</CardContent>
      </Collapse>
    </Card>
  );
};
