/* eslint-disable no-param-reassign */
/* eslint-disable guard-for-in */
import { OpenAPIV3 } from 'openapi-types';

const getComponentNameFromPath = (path: string) => {
  return path.substring(path.lastIndexOf('/') + 1);
};

const getRequestBody = (
  obj?: OpenAPIV3.OperationObject,
): OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject | undefined => {
  const requestBody = obj?.requestBody;
  if (requestBody && !('$ref' in requestBody)) {
    return requestBody.content?.['application/json'].schema;
  }

  return undefined;
};

const getResponseOK = (
  obj?: OpenAPIV3.OperationObject,
): OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject | undefined => {
  for (const code in obj?.responses) {
    if (parseInt(code, 10) < 300) {
      const response = obj?.responses[code];
      if (!('$ref' in response)) {
        return response.content?.['application/json'].schema;
      }
    }
  }
  return undefined;
};

export class SchemaWrapper {
  constructor(private schema: OpenAPIV3.Document) {}

  static parse(schema: string): SchemaWrapper {
    const json = JSON.parse(schema);
    return new SchemaWrapper(json);
  }

  private getFromRef(path: string) {
    return this.clearComponentFromRef(
      this.getComponentByName(getComponentNameFromPath(path)),
    );
  }

  private getComponentByName(name: string) {
    return this.schema?.components?.schemas?.[name];
  }

  private clearComponentFromRef(
    component: OpenAPIV3.ReferenceObject | OpenAPIV3.SchemaObject | undefined,
  ): OpenAPIV3.SchemaObject | undefined {
    if (!component) return component;

    if (component && '$ref' in component) {
      component = this.getFromRef(component.$ref);
    }

    if (component?.type === 'object') {
      for (const prop in component.properties) {
        const replaced = this.clearComponentFromRef(component.properties[prop]);
        if (replaced) {
          component.properties[prop] = replaced;
        }
      }
    } else if (component?.type === 'array') {
      const replaced = this.clearComponentFromRef(component.items);
      if (replaced) {
        component.items = replaced;
      }
    }

    return component as OpenAPIV3.SchemaObject;
  }

  getFromPath(pattern: string, method: string, type: 'request' | 'response') {
    const operationObject =
      this.schema.paths?.[pattern]?.[method as OpenAPIV3.HttpMethods];

    switch (type) {
      case 'request':
        return this.clearComponentFromRef(getRequestBody(operationObject));
      case 'response':
        return this.clearComponentFromRef(getResponseOK(operationObject));
      default:
        return undefined;
    }
  }

  *[Symbol.iterator]() {
    for (const pattern in this.schema.paths) {
      for (const method in this.schema.paths[pattern]) {
        for (const type of ['request', 'response'] as const) {
          yield {
            pattern,
            method,
            type,
            schema: this.getFromPath(pattern, method, type),
          };
        }
      }
    }
  }

  get paths() {
    const paths: Array<Record<'pattern' | 'method', string>> = [];
    for (const pattern in this.schema.paths) {
      for (const method in this.schema.paths[pattern]) {
        paths.push({ method, pattern });
      }
    }

    return paths;
  }

  get title() {
    return this.schema.info.title;
  }
}
