import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const DownloadSVGLink = ({ svg }: { svg: string }) => {
  const onCLick = () => {
    const blob = new Blob([svg], { type: 'image/svg+xml' });
    if (blob) {
      const url = URL.createObjectURL(blob);
      const tmp = document.createElement('a');
      document.body.appendChild(tmp);
      tmp.style.display = 'none';
      tmp.download = 'mermaid.svg';
      tmp.href = url;
      tmp.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Tooltip title="Download SVG" placement="top">
      <Button onClick={onCLick}>
        <CloudDownloadIcon />
      </Button>
    </Tooltip>
  );
};
