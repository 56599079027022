import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import { Row } from '../transformer/transformer';
import React from 'react';

type SchemaObjectComponentProps = {
  data: Row[];
};

export const SchemaObjectTable = ({ data }: SchemaObjectComponentProps) => {
  return (
    <TableContainer component={Paper}>
      <Table size="small" style={{ width: 'max-content' }}>
        <TableHead>
          <TableRow>
            {Object.keys(data[0]).map(name => (
              <TableCell>{name}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(row => (
            <TableRow>
              {Object.values(row).map(name => (
                <TableCell>{name}</TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
