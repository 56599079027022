import React, { useEffect, useState } from 'react';
import { SchemaWrapper } from '../transformer/wrapper';
import { PathExpand } from './RouteMetodComponent';
import { Alert, AlertTitle } from '@material-ui/lab';
import { OpenAPIToCSVTabbedCard } from './SchemaTabbedCard';

type OpenAPIToCSVComponentProps = {
  openApi: string;
};

export const OpenAPIToCSVComponent = ({
  openApi,
}: OpenAPIToCSVComponentProps) => {
  const [wrapper, setWrapper] = useState<SchemaWrapper | null>(null);
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    try {
      const instance = SchemaWrapper.parse(openApi);
      setWrapper(instance);
    } catch (err) {
      setError(error);
    }
  }, [openApi, error]);

  if (error) {
    return (
      <Alert severity="error">
        <AlertTitle>Error</AlertTitle>
        Invalid OpenApi Schema: <strong>{error.message}</strong>
      </Alert>
    );
  }

  return (
    <>
      {wrapper?.paths?.map(({ method, pattern }, id) => (
        <PathExpand name={pattern} method={method} key={id}>
          <OpenAPIToCSVTabbedCard
            wrapper={wrapper}
            pattern={pattern}
            method={method}
          />
        </PathExpand>
      ))}
    </>
  );
};
