import React, { useCallback } from 'react';
import { SchemaWrapper } from '../transformer/wrapper';
import { CardTab, TabbedCard } from '@backstage/core-components';
import { SchemaObjectComponent } from './SchemaObjectComponent';

type OpenAPIToCSVComponentProps = {
  wrapper: SchemaWrapper;
  pattern: string;
  method: string;
};

export const OpenAPIToCSVTabbedCard = ({
  wrapper,
  pattern,
  method,
}: OpenAPIToCSVComponentProps) => {
  const tabs = ['Request', 'Response'] as const;

  const getSchema = useCallback(
    (type: string) =>
      wrapper?.getFromPath(
        pattern,
        method,
        type === 'Request' ? 'request' : 'response',
      ),
    [wrapper, method, pattern],
  );

  const name = [
    wrapper?.title,
    pattern.substring(1).replaceAll('/', '.'),
    method.toUpperCase(),
  ].join('.');

  return (
    <TabbedCard value={getSchema('request') ? 0 : 1}>
      {tabs.map(tabName => {
        return (
          <CardTab label={tabName} disabled={!getSchema(tabName)}>
            <SchemaObjectComponent
              schema={getSchema(tabName)}
              title={name}
              type={tabName}
            />
          </CardTab>
        );
      })}
    </TabbedCard>
  );
};
