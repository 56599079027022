import { Header } from '@backstage/core-components';
import {
    HomePageRandomJoke,
    HomePageStarredEntities,
} from '@backstage/plugin-home';

import { HomePageSearchBar } from '@backstage/plugin-search';
import { AnnouncementsCard } from '@k-phoen/backstage-plugin-announcements';
import React from 'react';
import { Box, Grid } from '@material-ui/core';

export const HomePage = () => {
    return (
        <>
            <Header title="Home" />
            <Box sx={{ m: 2 }}>
                <HomePageSearchBar />
            </Box>

            <Grid container direction="row">
                <Grid item md={6}>
                    <Box sx={{ ml: 2, mr: 2 }}>
                        <AnnouncementsCard max={3} />
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{ ml: 2, mr: 2 }}>
                        <HomePageRandomJoke />
                    </Box>
                </Grid>
                <Grid item md={6}>
                    <Box sx={{ ml: 2, mr: 2 }}>
                        <HomePageStarredEntities />
                    </Box>
                </Grid>
            </Grid>
        </>

    );
};