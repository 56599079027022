import React from 'react';
import { Button, Container, Modal, Tooltip } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import OpenWith from '@material-ui/icons/OpenWith';
import { MermaidChart } from './MermaidChart';

export const MermaidChartModal = ({ svg }: { svg: string }) => {
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="Full Screen" placement="top">
        <Button onClick={handleClickOpen}>
          <OpenWith />
        </Button>
      </Tooltip>
      <Modal open={open} onClose={handleClose}>
        <Container
          style={{
            height: '100vh',
            width: '100vw',
            backgroundColor: '#F8F8F8',
          }}
        >
          <Button
            onClick={handleClose}
            aria-label="close"
            size="small"
            startIcon={<CloseIcon />}
          >
            close
          </Button>
          <MermaidChart code={svg} style={{ height: '100%' }} />
        </Container>
      </Modal>
    </>
  );
};
