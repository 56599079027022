import React from 'react';
import { Button, Tooltip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';

export const DownloadCSV = ({ csv, title }: { csv: string; title: string }) => {
  const date = new Date().toISOString().split('T')[0];
  const filename = `${date}_${title.replaceAll('.', '-')}.csv`;
  const onCLick = () => {
    const blob = new Blob([csv], { type: 'text/csv' });
    if (blob) {
      const url = URL.createObjectURL(blob);
      const tmp = document.createElement('a');
      document.body.appendChild(tmp);
      tmp.style.display = 'none';
      tmp.download = filename;
      tmp.href = url;
      tmp.click();
      URL.revokeObjectURL(url);
    }
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <Tooltip title={filename} placement="top">
      <Button onClick={onCLick}>
        <CloudDownloadIcon />
      </Button>
    </Tooltip>
  );
};
