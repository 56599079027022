

export const stringify = (arr: Array<Record<string, string>>): string => {
    const separator = "|";
  
    const rows = [Object.keys(arr[0])].concat(
      arr.map((row) => Object.values(row).map((item) => `"${item}"`)),
    );
  
    return rows.map((row) => row.join(separator)).join("\n");
  };
