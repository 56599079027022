/*
 * Copyright 2022 The Backstage Authors
 *
 * Licensed under the Apache License, Version 2.0 (the "License");
 * you may not use this file except in compliance with the License.
 * You may obtain a copy of the License at
 *
 *     http://www.apache.org/licenses/LICENSE-2.0
 *
 * Unless required by applicable law or agreed to in writing, software
 * distributed under the License is distributed on an "AS IS" BASIS,
 * WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
 * See the License for the specific language governing permissions and
 * limitations under the License.
 */

import React, { ReactElement, useEffect, useState } from 'react';
import { PaletteType, Theme, ThemeProvider, useTheme } from '@material-ui/core';
import mermaid, { MermaidConfig } from 'mermaid';
import { createPortal } from 'react-dom';
import { useShadowRootElements } from '@backstage/plugin-techdocs-react';

import { MermaidProps } from './props';
import { MermaidTabbedCardComponent } from './MermaidTabbedCard';

export function selectConfig(
  backstagePalette: PaletteType,
  properties: MermaidProps,
): MermaidConfig {
  // Theme set directly in the Mermaid configuration takes
  // precedence for backwards-compatibility
  if (properties.config) {
    return properties.config;
  }

  if (backstagePalette === 'light') {
    return properties.lightConfig || {};
  }

  return properties.darkConfig || {};
}

const createParentNode = (pre: HTMLPreElement, id: string) => {
  const rootDiv = document.createElement('div');
  rootDiv.className = 'mermaid-container';
  rootDiv.id = `mermaid-container-${id}`;
  pre.parentNode?.replaceChild(rootDiv, pre);
  return rootDiv;
};

const MermaidComponent = ({
  pre,
  id,
  theme,
}: {
  pre: HTMLPreElement;
  id: string;
  theme: Theme;
}) => {
  const code = pre.querySelector('code')?.innerText ?? '';
  const parent = createParentNode(pre, id);

  return (
    <>
      {createPortal(
        <ThemeProvider theme={theme}>
          <MermaidTabbedCardComponent code={code} id={id} />
        </ThemeProvider>,
        parent,
      )}
    </>
  );
};

export const MermaidAddon = (properties: MermaidProps) => {
  const theme = useTheme();
  const nodes = useShadowRootElements<HTMLPreElement>(['pre.mermaid']);
  const [parents, setParents] = useState<Array<ReactElement>>([]);

  useEffect(() => {
    const config = selectConfig(theme.palette.type, properties);
    if (config) {
      mermaid.initialize({
        startOnLoad: false,
        ...config,
      });
    }

    if (nodes.length && !parents.length) {
      setParents(
        nodes.map((pre, id) => (
          <MermaidComponent
            key={id + 1}
            pre={pre}
            id={`${id + 1}`}
            theme={theme}
          />
        )),
      );
    }
  }, [nodes, theme, parents.length, properties]);

  return <>{parents}</>;
};
