import React, { useEffect, useState } from 'react';
import mermaid from 'mermaid';
import { CardActions, CardContent } from '@material-ui/core';
import ErrorRounded from '@material-ui/icons/ErrorRounded';
import { MermaidChart } from './MermaidChart';
import { DownloadSVGLink } from './MermaidLinks';
import { MermaidChartModal } from './MermaidChartModal';

const createDiagram = async (code: string, id: string) => {
  try {
    if (await mermaid.parse(code)) {
      const { svg } = await mermaid.render(`mermaid-diagram-${id}`, code);
      return svg;
    }
  } catch (error) {
    throw error;
  }

  throw new Error('Invalid Mermaid diagram');
};

export const MermaidChartTabCard = ({
  code,
  id,
}: {
  code: string;
  id: string;
}) => {
  const [SVG, setSVG] = useState<string>('');
  const [error, setError] = useState<Error | null>(null);

  useEffect(() => {
    let isMounted = true;
    createDiagram(code, id)
      .then(svg => {
        if (isMounted) setSVG(svg);
      })
      .catch((err: Error) => {
        if (isMounted) setError(err);
      });
    return () => {
      isMounted = false;
    };
  }, [code, id]);

  if (error)
    return (
      <div>
        <ErrorRounded />
        Error rendering diagram {error.message}
      </div>
    );
  if (!SVG) return <div>Rendering...</div>;
  return (
    <>
      <CardActions>
        <MermaidChartModal svg={SVG} />
        <DownloadSVGLink svg={SVG} />
      </CardActions>
      <CardContent>
        <MermaidChart code={SVG} style={{ height: '400px' }} />
      </CardContent>
    </>
  );
};
